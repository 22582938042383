<template>
  <div>
    <div class="avatar_wrapper flex_con_column">
      <p class="nickName">{{ info.name }}</p>
      <p class="userName">工号：{{ info.account }}</p>
      <div class="tress" v-if="cliUser  && cliUser.length >0">
        <div class="flex_con_column" v-for="item in cliUser[0].children" :key="item.path"
             @click="goRouter(item.path)"
        >
          <p class="image flex_con_column"><ch-icon :name="`avatar-${item.path}`" /></p>
          <span class="text">{{item.menuName}}</span>
        </div>
      </div>
      <div class="logout flex_con" @click="doLogout">退出登陆</div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  computed: {
    info: function () {
      const that = this
      if(that.userInfo) {
        return that.userInfo
      } else {
        return {
          nickName: '加载中',
          userName: '加载中'
        }
      }

    },
    ...mapGetters(['userInfo','cliUser'])
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    ...mapMutations(['saveTags']),
    ...mapActions(['logOut','getUserInfo']),
    // 退出登录
    doLogout() {
      this.saveTags([])
      this.logOut()
    },
    goRouter(name){
      this.$router.push({ path: "/"+ name });
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar_wrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;

  .nickName {
    padding-top: 10px;
  }

  .tress {
    padding: 10px 10px;
    display: flex;
    > div {
      cursor: pointer;
      padding: 20px 6px;
      .image {
        font-size: 18px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: rgba(62, 126, 255, 0.1);
      }
      .text {
        padding: 6px;
      }
    }
  }
  .logout {
    width: 100%;
    border-top: 1px solid #F1F2F4;
    padding: 10px;
    cursor: pointer;

  }
}

.el-dropdown-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.system_dropdown{
  padding: 10px 10px;
}
.el-dropdown-menu__item{
  padding: 0 10px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
