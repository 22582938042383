<template>
  <div class="avatar_wrapper">
    <div >
      <el-badge class="item">
        <el-dropdown>
          <el-avatar :size="40" :src="avatar" @error="() => true">
            <img :src="tiger" alt="">
          </el-avatar>
          <el-dropdown-menu slot="dropdown">
            <avatar-menu/>
          </el-dropdown-menu>
        </el-dropdown>
      </el-badge>
    </div>
  </div>
</template>


<script>

import AvatarMenu from "@/views/admin/header/avatar/avatarMenu";
import tiger from "@/assets/img/views/avatar/tiger.jpg"
import { mapGetters } from "vuex";
export default {
  components: {AvatarMenu},
  data(){
    return {
      tiger: tiger
    }
  },
  computed: {
    avatar: function () {
      const that = this
      if (that.userInfo && that.userInfo.avatar) {
        return that.userInfo.avatar
      } else {
        return tiger
      }
    },
    ...mapGetters(['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
.avatar_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
